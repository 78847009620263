<template>
  <form v-on:submit.prevent>
    <div class="d-grid gtc-2 gap-24 mt-64">
      <div v-for="(field, index) in fields" :key="index">
        <label :for="field.name" class="text-h3 mb-12">{{ field.title }}</label>
        <textarea
          class="diffmatch-textarea"
          rows="12"
          :placeholder="field.placeholder"
          v-model="payload[field.name]"
          :disabled="field.filename != false"
          @focus="field.fileError = false">
        </textarea>

        <div v-if="!field.filename" class="d-flex jc-space-between align-center mt-12">
          <div class="text-base fw-600 color-text-darker">
            Supported file formats: .txt. Max size: 5 MB
          </div>
          <label :for="field.file"
            class="br-5 bw-2 b-style-solid pt-10 pr-16 pb-10 pl-16 fw-600 fs-13 lh-16 let-space-08 transition"
            :class="[
              payload[field.name] == false ? 'color-link-darker bc-link-darker cursor-pointer bg-link-darker_hover color-white_hover' : 'color-link'
            ]"
          >
            Upload the file
          </label>
          <input type="file"
            class="d-none"
            accept=".txt"
            :id="field.file"
            :disabled="payload[field.name] != false"
            ref="fileupload"
            @click="resetImage"
            @change="checkFile" />
        </div>

        <div v-if="field.filename" class="d-flex align-center jc-space-between py-4 mt-12">
          <div class="text-base fw-600 color-text-darker">
            {{ field.filename.toLowerCase() }}
          </div>
          <div class="w-32px h-32px bg-white bg-link_hover br-circle color-link color-white_hover d-flex ai-center jc-center cursor-pointer transition">
            <Icon name="trash-no-fill" class="w-15px h-15px" @click="deleteFile(field.file)" :id="field.file"/>
          </div>
        </div>

        <div v-if="field.fileError">
          <DiffMatchError title="Invalid file" description="The file is too big" />
        </div>
      </div>
    </div>

    <div v-if="serverError">
      <DiffMatchError title="Server error" description="Server answered with an error. Please, try later." />
    </div>

    <div v-if="computed">
      <h2 class="color-black fw-600 fs-22 lh-32 let-space-02 mt-32">Computed Version:</h2>
      <div class="max-h-250px custom-scrollbar w-100 mt-12 br-5 px-16 py-12 bg-form" v-html="computed"></div>
    </div>

    <div v-if="settingsOpen" class="color-text-darker mt-32">
      <div class="mb-32">
        <h2 class="text-h2 mb-24">Post-diff cleanup:</h2>
        <div class="d-grid gtc-3-auto gap-70">
          <label
            v-for="(cleanup, index) in cleanups"
            :key="index"
            class="input-radio d-grid"
          >
            <input
              type="radio"
              class="d-none"
              v-model="postDiffCleanup"
              :value="cleanup.value"
              :id="cleanup.value"
            >
            <span></span>
            <div class="d-flex">
              <div>
                <h3 class="text-h4 mb-4">{{ cleanup.title }}</h3>
                <p class="text-base color-text-darker" style="min-width: 250px;">{{ cleanup.description }}</p>
              </div>
              <input
                v-if="cleanup.value === 'efficiency'"
                class="input h-48px w-75px ml-20"
                type="number"
                min="0"
                v-model="editCost"
              />
            </div>
            <div v-if="cleanup.value === 'efficiency'">
              <input type="number" min="0" class="w-100px mr-20 ml-12 p-8 br-5" v-model="editCost">
            </div>
          </label>
        </div>
      </div>
      <div class="d-flex align-center jc-space-between">
        <div class="d-flex align-center">
          <h2 class="text-h2 no-wrap">Diff timeout:</h2>
          <input
            v-model="timeout"
            type="number"
            min="0"
            class="input w-100px h-48px ml-16"
          />
        </div>

        <label class="text-base color-text-darker ml-24">
          If the mapping phase of the diff computation takes longer than this, then the computation is truncated and the best solution to date is returned. While guaranteed to be correct, it may not be optimal. A timeout of '0' allows for unlimited computation.
        </label>
      </div>
    </div>

    <div class="d-flex jc-space-between mt-32">
      <div
          class="d-flex align-center cursor-pointer"
          id="diffmatch_settings"
          @click="toggleSettings()"
      >
        <Icon
          name="settings-fill"
          :class="[settingsOpen ? 'color-link' : 'color-black']"
        />
        <span
          class="text-h4 ml-10"
          :class="[settingsOpen ? 'color-link' : 'color-black']"
        >Settings</span>
      </div>

      <div>
        <button
          class="bttn-primary w-260px"
          id="diffmatch_submit"
          :disabled="(!this.payload.text_v1 && !this.payload.file_v1 ) || (!this.payload.text_v2 && !this.payload.file_v2)"
          @click="sendText()">
            Compute Diff
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import Icon from '@/components/app/Icon.vue';
import DiffMatchError from '@/components/diffmatch/Error';

export default ({
  name: 'DiffMatchBodyForm',
  components: {
    Icon,
    DiffMatchError
  },
  data () {
    return {
      fields: [
        {
          title: 'Text Version 1:',
          name: 'text_v1',
          file: 'file_v1',
          filename: '',
          placeholder: 'Past the original text',
          fileError: false
        },
        {
          title: 'Text Version 2:',
          name: 'text_v2',
          file: 'file_v2',
          filename: '',
          placeholder: 'Past the modified version',
          fileError: false
        }
      ],
      cleanups: [
        {
          title: 'Semantic Cleanup',
          description: 'Increase human readability by factoring out commonalities which are likely to be coincidental.',
          value: 'semantic'
        },
        {
          title: 'Efficiency Cleanup, edit cost:',
          description: 'Increase human readability by factoring out commonalities which are likely to be coincidental.',
          value: 'efficiency'
        },
        {
          title: 'No Cleanup',
          description: 'Raw output.',
          value: 'no_cleanup'
        }
      ],
      settingsOpen: false,
      payload: {
        text_v1: '',
        text_v2: '',
        file_v1: null,
        file_v2: null
      },
      serverError: false,
      computed: '',
      postDiffCleanup: 'semantic',
      timeout: null,
      editCost: null
    };
  },
  methods: {
    toggleSettings () {
      this.settingsOpen = !this.settingsOpen;
    },
    resetImage () {
      this.$refs.fileupload.value = null;
    },
    checkFile (e) {
      this.fields.filter(item => item.file === e.target.id)[0].fileError = false;
      if (e.target.files[0].size > 5242880) {
        this.fields.filter(item => item.file === e.target.id)[0].fileError = true;
        return;
      };
      this.fields.filter(item => item.file === e.target.id)[0].filename = e.target.files[0].name;
      this.payload[e.target.id] = e.target.files[0];
    },
    deleteFile (file) {
      this.payload[file] = null;
      this.fields.filter(item => item.file === file)[0].filename = '';
    },
    async sendText () {
      this.serverError = false;

      const formdata = new FormData();
      this.payload.text_v1 ? formdata.append('text_v1', this.payload.text_v1) : formdata.append('file_v1', this.payload.file_v1);
      this.payload.text_v2 ? formdata.append('text_v2', this.payload.text_v2) : formdata.append('file_v2', this.payload.file_v2);
      formdata.append('cleanup_type', this.postDiffCleanup);
      if (this.timeout) formdata.append('timeout', this.timeout);
      if (this.editCost && this.postDiffCleanup === 'efficiency') formdata.append('edit_cost', this.editCost);

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      await fetch('/api/matching/text', requestOptions)
        .then(response => response.json())
        .then(result => {
          this.computed = this.formatResult(result.response);
        })
        .catch(error => {
          console.log('error', error);
          this.serverError = true;
        });
    },
    formatResult (arr) {
      return arr.map(item => {
        return (item[0] === -1) ? '<span class="color-warning decor-through">' + item[1] + '</span>' : (item[0] === 1) ? '<span class="color-success">' + item[1] + '</span>' : '<span class="color-text">' + item[1] + '</span>';
      }).join('');
    }
  }
});
</script>

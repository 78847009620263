<template>
  <DiffMatchHeader :title="headerTitle" :subtitle="headerSubtitle" />
  <main class="mb-auto">
    <DiffMatchBodyForm />
  </main>
  <DiffMatchFooter />
</template>

<script>
import DiffMatchHeader from '@/components/diffmatch/Header';
import DiffMatchBodyForm from '@/components/diffmatch/BodyForm';
import DiffMatchFooter from '@/components/diffmatch/Footer';

export default ({
  name: 'DiffMatch',
  components: {
    DiffMatchHeader,
    DiffMatchBodyForm,
    DiffMatchFooter
  },
  data () {
    return {
      headerTitle: 'Diff, Match and Patch',
      headerSubtitle: 'Diff takes two texts and finds the differences. This implementation works on a character by character basis. The result of any diff may contain \'chaff\', irrelevant small commonalities which complicate the output. A post-diff cleanup algorithm factors out these trivial commonalities.'
    };
  }
});
</script>

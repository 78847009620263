<template>
  <section>
    <div class="d-flex align-center jc-space-between">
      <div class="text-h1">{{ title }}</div>
      <div>
        <MainLogo
          :img-width="48"
          :font-size="36"
          :margin-left="24"
        />
      </div>
    </div>

    <div class="menu-title mt-12">{{ subtitle }}</div>
  </section>
</template>

<script>
import MainLogo from '@/components/app/MainLogo';

export default ({
  name: 'DiffMatchHeader',
  components: {
    MainLogo
  },
  props: {
    title: {
      default: 'Some title is here'
    },
    subtitle: {
      default: 'Some subtitle will be here'
    }
  }
});
</script>

<template>
  <div class="input-error">
    <h2 v-if="title" class="fs-16 mb-2">{{ title }}</h2>
    <p>{{ description || 'Something went wrong' }}</p>
  </div>
</template>

<script>

export default ({
  name: 'DiffMatchError',
  props: ['title', 'description']
});
</script>

<template>
  <div class="d-flex align-center jc-space-between mt-60">
    <div class="d-flex ai-center">
      <a v-for="(socialicon, index) in socialicons" :key="index" class="w-32px h-32px d-flex ai-center mr-12" :href="socialicon.url" target="_blank">
        <Icon :name="socialicon.name" color="#000000" />
      </a>
    </div>

    <div class="text-h4">
      Any questions?&nbsp;&nbsp;<a href="https://sociallinks.io/contact-us" class="color-link-violet">Write to us</a>
    </div>
  </div>
</template>

<script>

import Icon from '@/components/app/Icon.vue';

export default ({
  name: 'DiffMatchFooter',
  components: {
    Icon
  },
  data () {
    return {
      socialicons: [
        {
          name: 'youtube',
          url: 'https://www.youtube.com/c/SocialLinksMaltegotransforms'
        },
        {
          name: 'twitter',
          url: 'https://twitter.com/_SocialLinks_'
        },
        {
          name: 'linkedin',
          url: 'https://www.linkedin.com/company/social-links/'
        },
        {
          name: 'facebook',
          url: 'https://www.facebook.com/Social-Links-Maltego-Transforms-810934209023184'
        }
      ]
    };
  }
});
</script>
